<template>
  <div class="nav-header">
    <b-navbar toggleable="lg" type="light" variant="" :class="navScroll">
      <b-navbar-brand :to="{ name: 'index' }">
        <img class="logo" :src="logoImg" alt="" height="35" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'index' }" class="b-nav-item">{{
            $t("nav.Home")
          }}</b-nav-item>
          <b-nav-item :to="{ name: 'timeline' }">
            {{ $t("nav.Lounge") }}
          </b-nav-item>

          <b-nav-item-dropdown>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              {{ $t("profile.Services") }}
            </template>
            <b-dropdown-item class="info" :to="{ name: 'company-list' }">
              {{ $t("nav.Company") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'investor-list' }">
              {{ $t("nav.Investor") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'freelancer-list' }">
              {{ $t("nav.Freelancer") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'broker-list' }">
              {{ $t("nav.Broker") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'advisor-list' }">
              {{ $t("nav.Advisor") }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item href="#"
            >Tikbic
            <span class="badge badge-danger badge-sm">SOON</span></b-nav-item
          >

          <b-nav-item-dropdown>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              {{ $t("nav.Info") }}
            </template>
            <b-dropdown-item class="info" :to="{ name: 'about-us' }">
              {{ $t("nav.About") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'contact-us' }">
              {{ $t("nav.Contact Us") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'pages-blog-list' }">
              {{ $t("nav.Blog") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'faq-land' }"
              >{{ $t("nav.FAQ") }}
            </b-dropdown-item>
            <b-dropdown-item class="info" :to="{ name: 'pricing' }"
              >{{ $t("nav.Pricing") }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- <b-nav-form>
            <b-input-group class="input-group-merge">
              <b-form-input placeholder="Search" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-nav-form> -->
        </b-navbar-nav>

        <!-- Right aligned nav items  multiLanguage-->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="">
            <locale />
          </b-nav-item>
        </b-navbar-nav>

        <!-- <div class="ml-auto">
          <b-button
            v-if="!$store.getters['auth/isAuth']"
            class="registerBtn"
            variant="gradient-primary"
            :to="{ name: 'auth-login' }"
          >
            {{ $t("nav.Register") }}
          </b-button>
          <b-button
            v-if="$store.getters['auth/isAuth'] && ($store.getters['auth/getRole'] == 'Partner'||$store.getters['auth/getRole'] == 'Super-admin'||$store.getters['auth/getRole'] == 'Employee'||$store.getters['auth/getRole'] == 'User') "
            class="registerBtn"
            variant="gradient-primary"
            :to="{
              name: 'userprofile',
              params: { username: $store.getters['auth/user'].username },
            }"
          >{{ $t("nav.My Account") }}</b-button>
          <b-button
            v-if="$store.getters['auth/isAuth'] && $store.getters['auth/getRole'] == 'Guest'"
            class="registerBtn"
            variant="gradient-primary"
            :to="`/account-type`"
          >{{ $t("nav.Create Account") }}
          </b-button>
        </div> -->
        <b-nav-item-dropdown
          v-if="isAuth"
          style="list-style-type: none"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content class="p-0 m-0">
            <b-avatar
              v-if="userData && userData.profileImage"
              size="40"
              :src="userData.profileImage"
              variant="light-primary"
              badge
              class="badge-minimal pt-0 mt-0"
              badge-variant="success"
            >
              <!-- <feather-icon
                v-if="userData && userData.username"
                icon="UserIcon"
                size="22"
              /> -->
            </b-avatar>
          </template>

          <b-dropdown-item
            v-if="
              userData &&
              userData.username &&
              (role === 'Partner' || role === 'Super-admin' || role === 'User')
            "
            :to="{
              name: 'userprofile',
              params: { username: userData.username },
            }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>{{ $t("dashboard.Profile") }}</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'meeting' }">
            <feather-icon size="16" icon="VideoIcon" class="mr-50" />
            <span>{{ $t("dashboard.StartMeeting") }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              userData &&
              userData.username &&
              (role === 'Partner' || role === 'Super-admin' || role === 'User')
            "
            :to="{ name: 'bookmarks' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="BookmarkIcon" class="mr-50" />
            <span>{{ $t("dashboard.MyBookmarks") }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              userData &&
              userData.username &&
              (role === 'Partner' || role === 'Super-admin' || role === 'User')
            "
            :to="{ name: 'inbox' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
            <span>{{ $t("dashboard.Chat") }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="
              userData &&
              userData.username &&
              (role === 'Partner' || role === 'Super-admin' || role === 'User')
            "
            :to="{ name: 'pages-account-setting' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>{{ $t("dashboard.Settings") }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="userData && userData.username && role === 'Guest'"
            :to="{ name: 'account-type' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
            <span>{{ $t("nav.Create Account") }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>{{ $t("dashboard.Logout") }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button
          v-if="!isAuth"
          class="registerBtn ml-2"
          size="sm"
          variant="primary"
          :to="{ name: 'auth-register' }"
          >{{ $t("nav.Register") }}</b-button
        >
        <b-button :to="{ name: 'auth-login' }" v-if="!isAuth" variant="link">{{
          $t("profileDetails.Login")
        }}</b-button>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BAvatar,
  BNavItemDropdown,
  BDropdownItem,
  BIcon,
  BButton,
} from "bootstrap-vue";
import Locale from "./Locale.vue";

import Notifacation from "./notifacation.vue";
import UserDropdown from "../../@core/layouts/components/app-navbar/components/UserDropdown.vue";
import Currency from "./Currency.vue";

export default {
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    ToastificationContent,
    BNavItem,
    BAvatar,
    BNavItemDropdown,
    BDropdownItem,
    Notifacation,
    BIcon,
    UserDropdown,
    BButton,
    Locale,
    Currency,
  },
  data() {
    return {
      navScroll: "nav-headerTrans",
      navLink: "navLink-White",
      // logoImg: "require(../../assets/images/logo/logo-nft-light-nav.png)",
      logoImg: require("@/assets/images/logo/" +
        (this.$i18n.locale == "en" ? "aic.png" : "aic_ar.png")),
      navLinks: "navLinks-white",

      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      userData: ["auth/user"],
      isAuth: ["auth/isAuth"],
      role: ["auth/getRole"],
    }),
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    if (this.windowWidth < 700) {
      this.navScroll = "nav-headerWhite";
      this.logoImg = require("@/assets/images/logo/aic.png");
      this.navLinks = "navLinks-dark";
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.logoutAction()
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("logout.successTitle")}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `${this.$t("logout.successText")}`,
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.$t("logout.successTitle")}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: error.message,
                },
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$router.push({ name: "index" });
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      // console.log(this.windowWidth);
      if (this.windowWidth > 700) {
        if (window.scrollY > 50) {
          this.navScroll = "nav-headerWhite";
          this.logoImg = require("@/assets/images/logo/" +
            (this.$i18n.locale == "en" ? "aic.png" : "aic_ar.png"));
          this.navLinks = "navLinks-dark";
        }
        if (window.scrollY <= 50) {
          this.navScroll = "nav-headerTrans";
          this.logoImg = require("@/assets/images/logo/" +
            (this.$i18n.locale == "en" ? "aic.png" : "aic_ar.png"));
          this.navLinks = "navLinks-white";
        }
      } else {
        this.navScroll = "nav-headerWhite";
        this.logoImg = require("@/assets/images/logo/" +
          (this.$i18n.locale == "en" ? "aic.png" : "aic_ar.png"));
        this.navLinks = "navLinks-dark";
      }
    },
  },
};
</script>

<style scoped>
.nav-header {
  /* margin-bottom: 110px; */
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
.navbar img {
  margin-left: 30px;
}

[dir] .bg-info {
  background-color: #ffffff !important;
}

.navbar-light .navbar-nav li {
  margin-left: 15px;
}

[dir="ltr"] .carousel-indicators {
  display: none;
}

.navbar-nav .user-account {
  display: flex;
  align-content: stretch;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.user-account .dropdown-menu {
  width: 200px;
}
.user-account .create-profile ul {
  width: 225px;
  font-size: 16px;
}
[dir="ltr"] .navbar img {
  margin-left: 0px;
}
@media (min-width: 920px) {
  .nav-header .input-search {
    width: 250px;
    padding: 20px;
    font-size: 17px;
  }

  .create-nft {
    padding: 5px 15px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    background-color: #366ce3;
    transition: 0.3s;
    cursor: pointer;
  }

  [dir] .create-nft:hover {
    background-color: #2454bc;
    transform: translateY(-5px);
    box-shadow: 0px 20px 50px rgb(0 0 0 / 10%);
  }

  .navbar-light .navbar-nav .create-nft .nav-link:hover {
    color: #000000;
  }

  .nav-headerTrans {
    background-color: #e9f2fe !important;
    transition: all 0.75s ease;
  }
  .nav-headerWhite {
    background-color: #fff;
    transition: all 0.75s ease;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
  }
}

[dir="ltr"] .bg-gradient-primary,
[dir="ltr"] .btn-gradient-primary {
  border-radius: 10px;
}

.navbar-light .navbar-nav .info {
  margin-left: 3px;
}
.btn .dropdown-toggle::after {
  display: none;
}
.badge.badge-sm {
  font-size: 10px;
  font-weight: normal;
  padding: 3px;
}
</style>
<style>
.navbar-light .navbar-nav .nav-link {
  color: #000000 !important;
  font-weight: normal;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #221395 !important;
}
</style>
