<template>
  <div>
    <div class="footer">
      <b-container class="footer-container" fluid>
        <b-row>
          <b-col sm="12" md="3" class="footer-block mt-5">
            <b-link :to="{ name: 'index' }" class="logo-otr">
              <!-- <a> -->
              <img
                class="logo-img"
                src="../../assets/images/logo/logo-footer.png"
                alt="brand-logo"
              />
              <!-- </a> -->
            </b-link>
          </b-col>
          <b-col sm="12" md="2" class="footer-block mt-5">
            <h4 class="mb-2">{{ $t("footer.Services") }}</h4>
            <div class="mb-1">
              <b-link :to="{ name: 'company-list' }">
                {{ $t("footer.Companies") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'investor-list' }">
                {{ $t("footer.Investors") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'freelancer-list' }">
                {{ $t("footer.Freelancers") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'broker-list' }">
                {{ $t("footer.Brokers") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'advisor-list' }">
                {{ $t("footer.Advisors") }}
              </b-link>
            </div>
          </b-col>
          <b-col sm="12" md="2" class="footer-block mt-5">
            <h4 class="mb-2">{{ $t("footer.Plans") }}</h4>
            <div class="mb-1">
              <b-link :to="{ name: 'pricing' }">
                {{ $t("footer.Company_pricing") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'pricing' }">
                {{ $t("footer.Investor_pricing") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'pricing' }">
                {{ $t("footer.Freelancer_pricing") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'pricing' }">
                {{ $t("footer.Broker_pricing") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'pricing' }">
                {{ $t("footer.Advisor_pricing") }}
              </b-link>
            </div>
          </b-col>
          <b-col sm="12" md="2" class="footer-block mt-5">
            <h4 class="mb-2">{{ $t("footer.Info") }}</h4>
            <div class="mb-1">
              <b-link :to="{ name: 'about-us' }">
                {{ $t("footer.About") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'how-it-works' }">
                {{ $t("footer.How_it_works") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'why-aic' }">
                {{ $t("footer.Why_AIC") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'pages-blog-list' }">
                {{ $t("footer.News") }}
              </b-link>
            </div>

            <div class="mb-1">
              <b-link :to="{ name: 'pages-faq' }">
                {{ $t("footer.FAQ") }}
              </b-link>
            </div>
          </b-col>
          <b-col sm="12" md="2" class="footer-block mt-5">
            <h4 class="mb-2">{{ $t("footer.terms") }}</h4>
            <div class="mb-1">
              <b-link :to="{ name: 'privacy-policy' }">
                {{ $t("footer.privacy_policy") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'terms-conditions' }">
                {{ $t("footer.terms_and_conditions") }}
              </b-link>
            </div>
            <div class="mb-1">
              <b-link :to="{ name: 'copyright-policy' }">
                {{ $t("footer.copyright_policy") }}
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-row>
        <b-col sm="12" md="6"></b-col>
        <b-col sm="12" md="6">
          <div class="contact-social">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <!-- <i class="fa-brands fa-facebook" /> -->

                  <img
                    src="../../assets/images/info/facebookIcon.png"
                    alt=""
                    width="30"
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <!-- <i class="fa-brands fa-instagram"/> -->
                  <img
                    src="../../assets/images/info/instagramIcon.png"
                    alt=""
                    width="30"
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <!-- <i class="fa-brands fa-linkedin" /> -->
                  <img
                    src="../../assets/images/info/linkedIcon.png"
                    alt=""
                    width="30"
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <!-- <i class="fa-brands fa-twitter" /> -->
                  <img
                    src="../../assets/images/info/twitterIcon.png"
                    alt=""
                    width="30"
                  />
                </a>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row class="copy-otr">
        <b-col sm="12" md="5" class="">
          <div class="copy-name body-s ml-2">
            <!-- <span> {{ $t("footer.powerdBy") }} </span>

            <img src="../../assets/images/logo/logonft.png" height="40px" /> -->
          </div>
        </b-col>
        <b-col sm="12" md="12" class="text-center">
          <div class="copy-name body-s">
            {{ $t("footer.Copyright") }}
            <a class="name body-sb">{{ $t("footer.NFT_Dubai_Holding") }}</a>
            {{ $t("footer.All_rights_reserved") }}
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BContainer, BRow, BCol, BLink } from "bootstrap-vue";
import Notifacation from "./notifacation.vue";
import UserDropdown from "../../@core/layouts/components/app-navbar/components/UserDropdown.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer {
  background-color: #1e222d;
  margin-bottom: 20px !important;
}
.footer .logo-otr .logo-img {
  width: 100px;
}
/* .footer-block {
  margin-top: 22px;
} */

.footer-block h4 {
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: -1px;
  padding: 2px;
  font-size: 16px;
}
.footer-block p {
  font-weight: 500;
  padding: 2px;
}
.footer-block p:last-child {
  font-weight: 500;
  padding: 2px;
  padding-top: -2px;
}
.footer-block div a {
  font-size: 14px;
  color: #a0a0a0;
}
.footer-block div a:hover {
  color: #fff;
}
@media (max-width: 540px) {
  .footer-block {
    padding-left: 30px;
  }
}
.contact-social ul {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: center;
  list-style: none;
}

.contact-social ul li {
  margin: 12px;
}
.contact-social ul li .fa-brands {
  font-size: 25px;
  color: #366ce3;
}
.contact-social ul li .fa-brands:hover {
  color: #fff;
}
.copy-otr {
  /* text-align: center; */
  font-size: 17px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.copy-otr a {
  color: #fff;
}
.copy-otr a:hover {
  text-decoration: underline;
}

.footer-container {
  padding: 50px 50px 0 50px;
}

@media (max-width: 540px) {
  .footer .row {
    margin-right: 0;
    margin-left: 0;
  }
  .footer-container {
    padding: 2px;
  }
}
</style>
