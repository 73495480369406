import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from "axios";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth/auth";
import profile from "./profile/profile";
import country from "./country/country";
import currency from "./currency/currency";
import industry from "./industry/industry";
import timeline from "./timeline/index";
import locale from "./locale/locale";
import myCurrency from "./myCurrency/myCurrency";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
// axios.defaults.baseURL = "https://plankton-app-9npsd.ondigitalocean.app/api/";
// axios.defaults.baseURL = "http://192.168.0.200:3000/api/";
axios.defaults.baseURL = "https://api.arabinvestmentclub.com/api/";
//axios.defaults.baseURL = "http://localhost:3000/api/";

Vue.use(Vuex);
axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("accessToken");
    router.push("/login");
  }
  if (error.response.status === 403) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: error.response.data.message,
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
    router.replace("/pages/pricing");
  }
  return Promise.reject(error);
});

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    country,
    profile,
    currency,
    industry,
    timeline,
    locale,
    myCurrency,
    "app-ecommerce": ecommerceStoreModule,
  },
  strict: process.env.DEV,
});
