import CompanyCreate from "@/views/pages/create-account-wizard/CompanyCreateAccount.vue";
import FreelancerCreate from "@/views/pages/freelancer/CreateProfile.vue";
import store from "../../store";
import createProfileWithOutService from "./middle-wares/profiles/createProfilesWithOutService";
import createServiceProfile from "./middle-wares/profiles/createServiceProfile";
import adminMiddleware from "./middle-wares/dashboard/adminMiddleware";
import userMiddleware from "./middle-wares/dashboard/loginMiddleware";
import partnerMiddleware from "./middle-wares/dashboard/partnerMiddleware";
import guestMiddleware from "./middle-wares/dashboard/guestMiddleware";
import employeeMiddleware from "./middle-wares/dashboard/employeeMiddleware";
import completeAccountRegistration from "./middle-wares/profiles/completeAccountRegistration";
import loginMiddleware from "./middle-wares/dashboard/loginMiddleware";
import partnerSuperEmployee from "./middle-wares/dashboard/partnerSuperEmployee";

export default [
  {
    path: "/error-404",
    name: "error-404",
    // component: () => import("@/views/error/Error404.vue"),
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/payment/checkout/:stripeToken",
    name: "successfulPayment",
    component: () => import("@/views/pages/authentication/SuccessPaid.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/unsuccessfull/payment/:id",
    name: "unSuccessfulPayment",
    component: () => import("@/views/pages/authentication/UnSuccessPaid.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/index",
    name: "index",
    component: () => import("@/views/landpage/Home.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/pages/info/Contact.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/contact-us2",
    name: "contact-us2",
    component: () => import("@/views/pages/info/Contact2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/company/create",
    name: "company-create",
    component: CompanyCreate,
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create company profile",
          to: "/company/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/company/list",
    name: "company-list",
    component: () => import("@/views/pages/company/List-2.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      // pageTitle: "Company list",
      layout: "horizontal",
      // breadcrumb: [
      //   {
      //     text: "Company List",
      //     active: true,
      //   },
      // ],
      // layout: {
      //   menu: {
      //     isHidden: true,
      //   },
      // },
    },
  },
  {
    path: "/company/deatails5",
    name: "company-list5",
    component: () =>
      import("@/views/pages/company/company-details/CompanyDetails.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Company list",
      layout: "full",
      breadcrumb: [
        {
          text: "Company List",
          active: true,
        },
      ],
      layout: {
        menu: {
          isHidden: true,
        },
      },
    },
  },
  {
    // path: '/company/list/:id',
    // name: 'company-list-details',
    // component: () => import('@/views/pages/company/company-details/CompanyDetails.vue'),
    path: "/freelancer/list",
    name: "freelancer-list",
    component: () => import("@/views/pages/freelancer-filter/List2.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      // pageTitle: "Freelancer list",
      layout: "horizontal",
      // breadcrumb: [
      //   {
      //     text: "Freelancer List",
      //     active: true,
      //   },
      // ],
      layout: {
        menu: {
          isHidden: true,
        },
      },
    },
  },
  {
    path: "/investor/list",
    name: "investor-list",
    component: () => import("@/views/pages/investor-filter/List2.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      // pageTitle: "Investor list",
      layout: "horizontal",
      // breadcrumb: [
      //   {
      //     text: "Investor List",
      //     active: true,
      //   },
      // ],
      layout: {
        menu: {
          isHidden: true,
        },
      },
    },
  },
  {
    path: "/advisor/list",
    name: "advisor-list",
    component: () => import("@/views/pages/advisor-filter/List2.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      // pageTitle: "Advisor list",
      layout: "horizontal",
      // breadcrumb: [
      //   {
      //     text: "Advisor List",
      //     active: true,
      //   },
      // ],
      layout: {
        menu: {
          isHidden: true,
        },
      },
    },
  },
  {
    path: "/broker/list",
    name: "broker-list",
    component: () => import("@/views/pages/broker-filter/List2.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      // pageTitle: "Broker list",
      layout: "horizontal",
      // breadcrumb: [
      //   {
      //     text: "Broker List",
      //     active: true,
      //   },
      // ],
      layout: {
        menu: {
          isHidden: true,
        },
      },
    },
  },
  {
    path: "/company/list/:id",
    name: "company-list-details",
    component: () =>
      import("@/views/pages/company/company-details/CompanyDetails.vue"),
    meta: {
      pageTitle: "Company Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Company List",
          to: "/company/list",
        },
        {
          text: "Company Details",
          active: true,
        },
      ],
    },
  },
  {
    path: "/investor/create",
    name: "investor-create",
    component: () => import("@/views/pages/investor/CreateProfile.vue"),
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create investor profile",
          to: "/investor/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/freelancer/create",
    name: "freelancer-create",
    component: FreelancerCreate,
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create Freelancer profile",
          to: "/freelancer/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/advisor/create",
    name: "advisor-create",
    component: () => import("@/views/pages/advisor/CreateProfile.vue"),
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create Advisor profile",
          to: "/advisor/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/broker/create",
    name: "broker-create",
    component: () => import("@/views/pages/broker/CreateProfile.vue"),
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create Broker profile",
          to: "/broker/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/validator/create",
    name: "validator-create",
    component: () => import("@/views/pages/validator/CreateProfile.vue"),
    meta: {
      middleware: [completeAccountRegistration],
      layout: "verticle",
      breadcrumb: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Create Validator profile",
          to: "/validator/create",
          active: true,
        },
      ],
    },
  },
  {
    path: "/idea/create",
    name: "idea-create",
    component: () => import("@/views/nftdubai/profilesForm/ideas/Create.vue"),
    //  component: () => import("@/views/forms/form-wizard/CreateFreelancerForm.vue"),
    meta: {
      middleware: [createProfileWithOutService],
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Create Idea profile",
      //     to: "/idea/create",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/ideas/listAll",
    name: "ideas-listAll",
    component: () => import("@/views/nftdubai/profilesForm/ideas/ListAll.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Ideas List All ",
      //     to: "ideas-listAll",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/ideas/edit/:id",
    name: "idea-edit",
    component: () => import("@/views/nftdubai/profilesForm/ideas/Edit.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Ideas List All ",
      //     to: "/ideas/listAll",
      //   },
      //   {
      //     text: "Edit Idea Profile ",
      //     to: "",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/ideas/details/:id",
    name: "idea-details",
    redirect: "/profile/details/:id",
    // component: () => import("@/views/nftdubai/profilesForm/ideas/Details.vue"),
    meta: {
      layout: "full",
    },
  },
  // -------- project profile Route -------------------//
  {
    path: "/project/create",
    name: "project-create",
    component: () => import("@/views/nftdubai/profilesForm/project/Create.vue"),
    //  component: () => import("@/views/forms/form-wizard/CreateFreelancerForm.vue"),
    meta: {
      middleware: [createProfileWithOutService],
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Create Project profile",
      //     to: "/project/create",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/project/listAll",
    name: "project-listAll",
    component: () =>
      import("@/views/nftdubai/profilesForm/project/ListAll.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Projects",
      //     to: "project-listAll",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/project/edit/:id",
    name: "project-edit",
    component: () => import("@/views/nftdubai/profilesForm/project/Edit.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Projects",
      //     to: "/project/listAll",
      //   },
      //   {
      //     text: "Edit Project Profile ",
      //     to: "",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/project/details/:id",
    name: "project-details",
    redirect: "/profile/details/:id",
    // component: () =>
    //   import("@/views/nftdubai/profilesForm/project/Details.vue"),
    meta: {
      layout: "full",
    },
  },

  // -------- product profile Route -------------------//
  {
    path: "/product/create",
    name: "product-create",
    component: () => import("@/views/nftdubai/profilesForm/product/Create.vue"),
    meta: {
      middleware: [createProfileWithOutService],
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Create Product profile",
      //     to: "/product/create",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/product/listAll",
    name: "product-listAll",
    component: () =>
      import("@/views/nftdubai/profilesForm/product/ListAll.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Products",
      //     to: "/product/listAll",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/product/edit/:id",
    name: "product-edit",
    component: () => import("@/views/nftdubai/profilesForm/product/Edit.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Product",
      //     to: "/product/listAll",
      //   },
      //   {
      //     text: "Edit Product Profile ",
      //     to: "",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/product/details/:id",
    name: "product-details",
    redirect: "/profile/details/:id",
    // component: () =>
    //   import("@/views/nftdubai/profilesForm/product/Details.vue"),
    meta: {
      layout: "full",
    },
  },
  // -------- service profile Route -------------------//

  {
    path: "/service/create",
    name: "service-create",
    component: () => import("@/views/nftdubai/profilesForm/service/Create.vue"),
    meta: {
      middleware: [createServiceProfile],
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "Create Service profile",
      //     to: "/service/create",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/service/listAll",
    name: "service-listAll",
    component: () =>
      import("@/views/nftdubai/profilesForm/service/ListAll.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Services",
      //     to: "service-listAll",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/service/edit/:id",
    name: "service-edit",
    component: () => import("@/views/nftdubai/profilesForm/service/Edit.vue"),
    meta: {
      layout: "verticle",
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     to: "/",
      //   },
      //   {
      //     text: "List All Services",
      //     to: "/service/listAll",
      //   },
      //   {
      //     text: "Edit Service Profile ",
      //     to: "",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/service/details/:id",
    name: "service-details",
    redirect: "/profile/details/:id",
    // component: () =>
    //   import("@/views/nftdubai/profilesForm/service/Details.vue"),
    meta: {
      layout: "full",
    },
  },

  // -------- Details  profile Route -------------------//

  {
    path: "/profile/details/:id",
    name: "profile-details",
    component: () => import("@/views/nftdubai/profilesForm/details/Details"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/copyright-policy",
    name: "copyright-policy",
    component: () => import("@/views/pages/terms/copyright_policy.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () => import("@/views/pages/terms/terms_conditions.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/pages/terms/privacy_policy.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/pages/info/About.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/how-it-works",
    name: "how-it-works",
    component: () => import("@/views/pages/info/how_it_works.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/authentication/login-v1",
    name: "auth-login-v1",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/login-v2",
    name: "auth-login-v2",
    component: () => import("@/views/pages/authentication/Login-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/register/:id",
    name: "auth-register-with-id",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/pages/authentication/register-v1",
    name: "auth-register-v1",
    component: () => import("@/views/pages/authentication/Register-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/register-v2",
    name: "auth-register-v2",
    component: () => import("@/views/pages/authentication/Register-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/authentication",
    name: "auth-forgot-password-v1",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/user/authenticated/:token",
    name: "auth-user",
    component: () =>
      import("@/views/pages/authentication/EmailVeryfication.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/forgot-password-v2",
    name: "auth-forgot-password-v2",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v2.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/authentication/reset-password-v1/:token",
    name: "auth-reset-password-v1",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-reset-password-v2",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/account-setting",
    name: "pages-account-setting",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      // pageTitle: "Account Settings",
      // breadcrumb: [
      //   {
      //     text: "Pages",
      //   },
      //   {
      //     text: "Account Settings",
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/pages/company-profile",
    name: "pages-company-profile",
    component: () => import("@/views/pages/company-profile/AccountSetting.vue"),
    meta: {
      pageTitle: "Company Profile",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Company Profile",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
    },
  },

  {
    path: "/pages/faq",
    name: "pages-faq",
    //component: () => import("@/views/pages/faq/Faq.vue"),
    component: () => import("@/views/pages/faq2/FaqLand.vue"),
    meta: {
      layout: "full",
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "faq-land",
    component: () => import("@/views/pages/faq2/FaqLand.vue"),
    meta: {
      layout: "full",
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/why-aic",
    name: "why-aic",
    //component: () => import("@/views/pages/faq/Faq.vue"),
    component: () => import("@/views/pages/why-aic/WhyAIC.vue"),
    meta: {
      layout: "full",
      pageTitle: "Why AIC",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Why AIC",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/knowledge-base",
    name: "pages-knowledge-base",
    component: () => import("@/views/pages/Knowledge-base/KnowledgeBase.vue"),
    meta: {
      pageTitle: "Knowledge Base",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/knowledge-base/:category",
    name: "pages-knowledge-base-category",
    component: () =>
      import("@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue"),
    meta: {
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          to: "/pages/Knowledge-base",
        },
        {
          text: "Category",
          active: true,
        },
      ],
      navActiveLink: "pages-knowledge-base",
    },
  },
  {
    path: "/pages/knowledge-base/:category/:slug",
    name: "pages-knowledge-base-question",
    component: () =>
      import("@/views/pages/Knowledge-base/KnowledgeBaseCategoryQuestion.vue"),
    meta: {
      pageTitle: "Question",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Knowledge Base",
          to: "/pages/Knowledge-base",
        },
        {
          text: "Category",
          to: "/pages/Knowledge-base/category",
        },
        {
          text: "Question",
          active: true,
        },
      ],
      navActiveLink: "pages-knowledge-base",
    },
  },
  {
    path: "/pages/pricing",
    name: "pages-pricing",
    beforeEnter(to, from, next) {
      if (store.getters["auth/isAuth"] === true) {
        return next();
      }
      return next({ name: "misc-not-authorized" });
    },
    component: () => import("@/views/pages/pricing/Pricing.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/pages/pricing/PublicPricing.vue"),
  },
  {
    path: "/my-subscriptions",
    name: "my-subscriptions",
    component: () => import("@/views/nftdubai/my-subscriptions/ListAll.vue"),
    meta: {
      layout: "verticle",
    },
  },

  {
    path: "/blog/list",
    name: "pages-blog-list",
    component: () => import("@/views/pages/blog/BlogList.vue"),
    meta: {
      layout: "full",

      pageTitle: "Blog List",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Blog",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/blog/:id",
    name: "pages-blog-detail",
    component: () => import("@/views/pages/blog/BlogDetail.vue"),
    meta: {
      pageTitle: "Blog Detail",
      layout: "full",
      // breadcrumb: [
      //   {
      //     text: 'Pages',
      //   },
      //   {
      //     text: 'Blog',
      //   },
      //   {
      //     text: 'Detail',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: "/pages/blog/edit/:id",
    name: "pages-blog-edit",
    component: () => import("@/views/pages/blog/BlogEdit.vue"),
    meta: {
      pageTitle: "Blog Edit",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Blog",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
];
